import { Box, Link, Typography } from '@mui/material';

export const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        mt: 3,
        width: '100%',
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Typography variant="body1" color="text.secondary" align="center">
        Cory Jbara
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
        Based in Chicago, IL |{' '}
        <Link href="mailto:cjbara@gmail.com" color="inherit">
          cjbara@gmail.com
        </Link>
      </Typography>
    </Box>
  );
};
