import { Box, Card, CardContent, Divider, Grid2 as Grid, Typography } from '@mui/material';
import { Building2, Code2, LineChart, Rocket, Target, Users } from 'lucide-react';
import React from 'react';
import { ICON_COLOR } from '../theme';

type ServiceGroup = {
  title: string;
  services: Service[];
};

type Service = {
  icon: React.ReactNode;
  title: string;
  description: React.ReactNode;
};

const serviceGroups: ServiceGroup[] = [
  {
    title: 'Strategic Planning',
    services: [
      {
        icon: <Target size={24} style={{ color: ICON_COLOR }} />,
        title: 'Value Proposition Development',
        description: (
          <>
            The most successful products solve real user problems. Together, we'll analyze your market landscape,
            interview potential customers, and craft a compelling story that positions your product as the best solution
            for your target audience.
          </>
        ),
      },
      {
        icon: <Building2 size={24} style={{ color: ICON_COLOR }} />,
        title: 'Revenue Model Design',
        description: (
          <>
            A strong revenue model is the foundation of a sustainable business. We'll explore different monetization
            strategies, develop pricing tiers that align with customer value, and create multiple revenue streams to
            support your long-term growth.
          </>
        ),
      },
    ],
  },
  {
    title: 'Technical Leadership',
    services: [
      {
        icon: <Rocket size={24} style={{ color: ICON_COLOR }} />,
        title: 'MVP Strategy',
        description: (
          <>
            Getting to market quickly with the right features is crucial for startup success. We'll define your core
            value proposition, identify must-have features, and create a lean development plan that gets your product in
            front of customers fast.
          </>
        ),
      },
      {
        icon: <Code2 size={24} style={{ color: ICON_COLOR }} />,
        title: 'Technical Foundation Planning',
        description: (
          <>
            Technical decisions made early have long-lasting impacts on your ability to scale. We'll evaluate technology
            options, design a flexible architecture, and create a technical roadmap that supports rapid growth while
            minimizing future rebuilds.
          </>
        ),
      },
    ],
  },
  {
    title: 'Growth & Operations',
    services: [
      {
        icon: <LineChart size={24} style={{ color: ICON_COLOR }} />,
        title: 'Customer Acquisition Planning',
        description: (
          <>
            Even great products need a clear path to reach customers. We'll develop your customer acquisition strategy,
            map the complete user journey, and establish key metrics to build a comprehensive strategy for attracting
            and retaining users.
          </>
        ),
      },
      {
        icon: <Users size={24} style={{ color: ICON_COLOR }} />,
        title: 'Product-Market Fit',
        description: (
          <>
            Finding product-market fit is a crucial milestone for any startup. We'll implement user research processes,
            establish feedback loops with early customers, and iterate on your product until you have clear evidence of
            market validation.
          </>
        ),
      },
    ],
  },
];

const ServicesList: React.FC = () => {
  return (
    <>
      {serviceGroups.map((group) => (
        <ServiceGroup key={group.title} title={group.title} services={group.services} />
      ))}
    </>
  );
};

const ServiceGroup = ({ title, services }: ServiceGroup) => (
  <Box sx={{ mb: 6 }}>
    <Typography variant="h5" sx={{ mb: 3 }} fontWeight="500">
      {title}
    </Typography>
    <Grid container spacing={3}>
      {services.map((service, index) => (
        <Grid size={{ xs: 12, md: 6 }} key={index}>
          <Card>
            <CardContent sx={{ display: 'flex', gap: 2 }}>
              <Box
                sx={{
                  p: 1,
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {service.icon}
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" gutterBottom>
                  {service.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {service.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export const MyServices: React.FC = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        My Services
      </Typography>
      <Typography variant="h6" color="text.secondary" gutterBottom>
        How can I help you build a valuable, scalable business?
      </Typography>
      <Divider sx={{ mt: 2 }} /> {/* Add visual separation */}
      <Box sx={{ mt: 2 }}>
        <ServicesList />
      </Box>
    </Box>
  );
};
