import { createTheme } from '@mui/material/styles';

const DARK_BLUE = '#1976D2';
const BLUE = '#2196F3';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
    secondary: {
      main: BLUE,
    },
  },
  typography: {
    fontFamily: '"Inter", "Arial", sans-serif',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 900,
      letterSpacing: '-0.02em',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
      color: '#666',
    },
    button: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '2rem',
          textTransform: 'none',
          padding: '0.75rem 2rem',
          fontSize: '1rem',
        },
        containedPrimary: {
          backgroundColor: DARK_BLUE,
        },
        outlinedPrimary: {
          borderColor: DARK_BLUE,
          color: DARK_BLUE,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: blues.gradient.dark,
    //     },
    //   },
    // },
  },
});

export const ICON_COLOR = theme.palette.secondary.main;

export default theme;
