import { Box, Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { AboutMe } from './components/AboutMe';
import { ContactMe } from './components/ContactMe';
import { Footer } from './components/Footer';
import { Intro } from './components/Intro';
import { MyServices } from './components/MyServices';
import { MyValues } from './components/MyValues';
import { Navbar } from './components/Navbar';
import './styles/json-style.css';
import { theme } from './theme';

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContent />
    </ThemeProvider>
  );
};

const Section = ({
  children,
  id,
  bgcolor = 'background.default',
}: {
  children: React.ReactNode;
  id: string;
  bgcolor?: string;
}) => (
  <Container sx={{ bgcolor, p: 2 }} id={id}>
    {children}
  </Container>
);

const AppContent: React.FC = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        spacing: 2,
      }}
    >
      <Navbar />
      <Section id="intro">
        <Intro />
      </Section>
      <Section id="about">
        <AboutMe />
      </Section>
      <Section id="services">
        <MyServices />
      </Section>
      <Section id="values">
        <MyValues />
      </Section>
      <Section id="contact">
        <ContactMe />
      </Section>
      <Footer />
    </Box>
  );
};
