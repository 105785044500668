import { CalendarMonth, Email, LinkedIn } from '@mui/icons-material';
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material';

export const SCHEDULE_MEETING_URL = 'https://cal.com/coryjbara/startups';

export const ContactMe: React.FC = () => {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h2" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
        Ready to Transform Your Startup?
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Schedule a call to map out your startup's path to success.
      </Typography>
      <Grid container spacing={2}>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<CalendarMonth />}
            href={SCHEDULE_MEETING_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Schedule a Call
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<Email />}
            href="mailto:cjbara@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email Me
          </Button>
        </Grid>
        <Grid>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<LinkedIn />}
            href="https://www.linkedin.com/in/cory-jbara"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect on LinkedIn
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
