import { Article, Email, LinkedIn } from '@mui/icons-material';
import { AppBar, Box, Container, IconButton, Link, Toolbar, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import theme from '../theme';

type IconData = {
  Icon: React.ElementType;
  link: string;
  tooltip: string;
};

const icons: IconData[] = [
  {
    Icon: LinkedIn,
    link: 'https://www.linkedin.com/in/cory-jbara/',
    tooltip: 'LinkedIn',
  },
  {
    Icon: Email,
    link: 'mailto:cjbara@gmail.com',
    tooltip: 'Email',
  },
  {
    Icon: Article,
    link: '/resume.pdf',
    tooltip: 'My Resume',
  },
];

const navItems = [
  { name: 'About Me', mobileName: 'About', link: '#about' },
  { name: 'My Services', mobileName: 'Services', link: '#services' },
  { name: 'My Values', mobileName: 'Values', link: '#values' },
];

export const Navbar = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <AppBar position="static" sx={{ backgroundColor: 'transparent' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {navItems.map(({ name, mobileName, link }) => (
              <Link key={name} color="primary.main" href={link} underline="none" sx={{ '&:hover': { opacity: 0.7 } }}>
                {isMobile ? mobileName : name}
              </Link>
            ))}
          </Box>
          <Box sx={{ display: 'flex', gap: 1, marginLeft: 2 }}>
            {icons.map(({ Icon, link, tooltip }) => (
              <Tooltip title={tooltip} key={tooltip}>
                <IconButton aria-label={tooltip} color="primary" href={link} target="_blank">
                  <Icon />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
