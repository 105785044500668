import { Box, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import { Handshake, LineChart, MessageCircle, Target, Wrench } from 'lucide-react';
import { ICON_COLOR } from '../theme';

const ValueItem = ({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) => (
  <Card
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    }}
  >
    <CardContent sx={{ flexGrow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ p: 2, fontSize: 40 }}>{icon}</Typography>
        <Typography variant="h5" component="h3">
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

export const MyValues: React.FC = () => {
  const values = [
    {
      icon: <Handshake size={24} style={{ color: ICON_COLOR }} />,
      title: 'A Technical Partnership',
      description:
        "I'm not just an advisor – I'm your partner on this journey. You're the expert in your own business, and I promise to complement your expertise with my fresh eyes and insights. Together, we'll discuss your vision, analyze your company's current state, and identify key opportunities. I'll offer my honest advice on next steps, but always with the understanding that you know your business best.",
    },
    {
      icon: <MessageCircle size={24} style={{ color: ICON_COLOR }} />,
      title: 'Open and Proactive Communication',
      description:
        "Clear, consistent communication is the cornerstone of our work together. I commit to staying on top of our interactions, keeping you informed every step of the way. We'll start by clearly defining our scope of work, and I'll provide regular updates as we progress. No guessing games – you'll always know where we stand and what's coming next.",
    },
    {
      icon: <Target size={24} style={{ color: ICON_COLOR }} />,
      title: 'High-Impact Opportunities',
      description:
        "My focus is on identifying and pursuing opportunities that will impact your business right now. Whether it's acquiring more users, optimizing your current product, or strategizing your next feature, I'm here to help you understand and act on what will make the biggest impact. It's not just about building features; it's about growing your business strategically.",
    },
    {
      icon: <LineChart size={24} style={{ color: ICON_COLOR }} />,
      title: 'Insightful Analysis',
      description:
        "I bring more than just technical skills to the table. My goal is to help you gain deeper insights into your business. Through data analysis, market research, and strategic thinking, we'll uncover new perspectives on your startup. This isn't about telling you what to do, but about equipping you with the knowledge to make informed decisions.",
    },
    {
      icon: <Wrench size={24} style={{ color: ICON_COLOR }} />,
      title: 'Tailored Solutions',
      description:
        "Every startup is unique, so cookie-cutter approaches don't cut it. I tailor my methods and recommendations to your specific needs, industry, and growth stage. Whether you need technical development, business strategy, or a bit of both, my diverse skill set allows me to adapt and provide exactly what your startup needs to thrive.",
    },
  ];

  return (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        My Values
      </Typography>
      <Typography variant="h6" color="text.secondary" gutterBottom>
        What can you expect when you work with me?
      </Typography>
      <Grid container spacing={4} sx={{ mt: 4 }}>
        {values.map((value, index) => (
          <Grid size={{ xs: 12, md: 6, lg: 4 }} key={index}>
            <ValueItem {...value} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
