import { Box, Card, CardContent, Typography } from '@mui/material';
import * as React from 'react';

export const AboutMe: React.FC = () => {
  return (
    <Box sx={{ mt: { xs: 4, sm: 0 } }}>
      <Typography variant="h4" component="h2" color="primary" sx={{ mb: 3 }}>
        About Me
      </Typography>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Hi, I'm Cory Jbara, a software engineer and MBA candidate at Chicago Booth. Over the past decade, I've been
            fortunate to work at many tech startups, learning and growing in different roles - from writing code to
            leading teams, to helping shape product strategies.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            My background spans full-stack development, API design, and early-stage product development. Though what
            really drives me is using these skills to solve real business problems.
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            I work closely with founders to navigate both the technical and business challenges of their startups. My
            approach? Direct, honest, and hands-on. I'm here to understand your vision, identify high-impact
            opportunities, and help turn your ideas into successful businesses.
          </Typography>
          <Typography variant="body1">
            When I'm not immersed in the startup world, you can find me walking around Chicago in one of my many
            flannels – cheering on Notre Dame and the Detroit Lions, hunting down the city's best wings, and though I
            love Chicago summers, I'm always itching to be on the slopes.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
